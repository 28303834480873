.parentContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left:0;
    right: 0;
    background-color: white;
    min-height: 60px;
    box-shadow: var(--navbar-shadow);
    padding: 10px;
    background-color: white;
    z-index: 10;
}
.childContainer{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoContainer{
    height: 50px;
    width: auto;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.abLogoImageContainer{
    height: 50px;
    width: 45px;
}
.logoName{
  padding-left:13px;
  font-weight: 900;
  font-size: var(--font-sm);
  cursor: default;
  margin: 0px;
}
.logoContainer img {
  width: 100%;
  height: 100%;
}
.mobileNavContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileNavContainer button {
  display: flex;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0, 0.2);
}
.mobileLinkContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    left: 0;
    right: 0;
    top:98%;
    padding: 3px;
    background-color: white;
}
.mobileLinkContainer a {
  text-decoration: none;
  padding: 7px;
}
.mobileNavContainer button img {
  width: 100%;
}
.showNavbarMenu {
  display: flex;
}
.collapseNavbarMenu {
  display: none;
}
.inactiveText {
  color: black;
}
.activeText {
  color: var(--primary-color);
  font-weight: 600;
}
.desktopLinkContainer{
    display: none;
    flex-basis: 55%;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (min-width:768px){
  .childContainer{
      width: 80%;
  }
  .desktopLinkContainer{
      display: flex;
  }
  .desktopLinkContainer a{
      text-decoration: none;
  }
  .mobileNavContainer{
      display: none;
  }
  .mobileLinkContainer{
      display: none;
  }
}
@media screen and (min-width:1024px){
  .desktopLinkContainer{
      flex-basis: 40%;
  }
  .abLogoImageContainer{
    height: 60px;
    width: 55px;
  }
}
