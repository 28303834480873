@import url(//fonts.googleapis.com/css?family=Avenir:900,800,600,400);
@import url(//fonts.googleapis.com/css?family=Montserrat:800,700,600,500,400);
body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  font-size: 66%;
  /* scroll-padding-top: 11.5%; */
  background-color: white;
}
#root{
  overflow: hidden;
}
:root {
  --font-xs:0.666rem;
  --font-s: 1rem;
  --font-sm: 1.4rem;
  --font-m: 1.875rem;
  --font-ml: 2.208rem;
  --font-l: 3rem;
  --font-xl: 8rem;
  --primary-color: #fa315a;
  --background-color: #f7f7f7;
  --light-grey: #e4e4e4;
  --gradient-red: #f50e4c;
  --gradient-dark-red: #af1d4a;
  --title-font-family: 'Montserrat';
  --description-font-family: 'Avenir';
  --navbar-shadow: 0px 2px 10px 0 rgba(24, 12, 12, 0.2);
  --image-shadow:0px 0px 14px 0px rgba(0,0,0,0.75);
  --angle: 90deg;
}
.titleText {
  font-size: var(--font-l);
  font-weight: 800;
  line-height: 1.3;
  font-family: var(--title-font-family);
}
.headingNormalText {
  font-size: var(--font-sm);
  font-weight: 800;
  line-height: 1.3;
  font-family: var(--title-font-family);
}
.bodyText {
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 1.4;
  font-family: var(--description-font-family);
}
.navbarText {
  font-size: var(--font-s);
  font-weight: 500;
  font-family: var(--title-font-family);
}
.readmoreText {
  font-size: var(--font-sm);
  font-weight: 600;
  font-family: var(--title-font-family);
}
.headingLargeText {
  font-size: var(--font-l);
  font-weight: 800;
  font-family: var(--title-font-family);
  color : var(--light-grey);
}
.headingText {
  font-size: var(--font-l);
  font-weight: 800;
  font-family: var(--title-font-family);
}
.center {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.footerButtonText {
  font-size: var(--font-s);
  line-height: 1.4;
  font-family: var(--title-font-family);
  font-weight: 500;
  cursor: pointer;
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
}
@media(min-width:768px){
  .headingLargeText{
    font-size: var(--font-xl);
  }
}
@media (min-width: 1024px) {
  html{
    font-size: 80%;
  }
}
@media (min-width: 1366px) {
  html{
    font-size: 100%;
  }
}
@media (min-width: 1900px) {
  html{
    font-size: 140%;
  }
}
@media (min-width: 2560px) {
  html{
    font-size: 175%;
  }
}
