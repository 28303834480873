@import url(//fonts.googleapis.com/css?family=Avenir:900,800,600,400);
@import url(//fonts.googleapis.com/css?family=Montserrat:800,700,600,500,400);
body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  font-size: 66%;
  /* scroll-padding-top: 11.5%; */
  background-color: white;
}
#root{
  overflow: hidden;
}
:root {
  --font-xs:0.666rem;
  --font-s: 1rem;
  --font-sm: 1.4rem;
  --font-m: 1.875rem;
  --font-ml: 2.208rem;
  --font-l: 3rem;
  --font-xl: 8rem;
  --primary-color: #fa315a;
  --background-color: #f7f7f7;
  --light-grey: #e4e4e4;
  --gradient-red: #f50e4c;
  --gradient-dark-red: #af1d4a;
  --title-font-family: 'Montserrat';
  --description-font-family: 'Avenir';
  --navbar-shadow: 0px 2px 10px 0 rgba(24, 12, 12, 0.2);
  --image-shadow:0px 0px 14px 0px rgba(0,0,0,0.75);
  --angle: 90deg;
}
.titleText {
  font-size: 3rem;
  font-size: var(--font-l);
  font-weight: 800;
  line-height: 1.3;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
}
.headingNormalText {
  font-size: 1.4rem;
  font-size: var(--font-sm);
  font-weight: 800;
  line-height: 1.3;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
}
.bodyText {
  font-size: 1.4rem;
  font-size: var(--font-sm);
  font-weight: 600;
  line-height: 1.4;
  font-family: 'Avenir';
  font-family: var(--description-font-family);
}
.navbarText {
  font-size: 1rem;
  font-size: var(--font-s);
  font-weight: 500;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
}
.readmoreText {
  font-size: 1.4rem;
  font-size: var(--font-sm);
  font-weight: 600;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
}
.headingLargeText {
  font-size: 3rem;
  font-size: var(--font-l);
  font-weight: 800;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
  color : #e4e4e4;
  color : var(--light-grey);
}
.headingText {
  font-size: 3rem;
  font-size: var(--font-l);
  font-weight: 800;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
}
.center {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.footerButtonText {
  font-size: 1rem;
  font-size: var(--font-s);
  line-height: 1.4;
  font-family: 'Montserrat';
  font-family: var(--title-font-family);
  font-weight: 500;
  cursor: pointer;
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
}
@media(min-width:768px){
  .headingLargeText{
    font-size: 8rem;
    font-size: var(--font-xl);
  }
}
@media (min-width: 1024px) {
  html{
    font-size: 80%;
  }
}
@media (min-width: 1366px) {
  html{
    font-size: 100%;
  }
}
@media (min-width: 1900px) {
  html{
    font-size: 140%;
  }
}
@media (min-width: 2560px) {
  html{
    font-size: 175%;
  }
}

.Icon_iconContainer__SGDWv {
  cursor: pointer;
}

.Icon_iconContainer__SGDWv i {
  color: var(--primary-color);
}

.Navbar_parentContainer__hy44j{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left:0;
    right: 0;
    background-color: white;
    min-height: 60px;
    box-shadow: var(--navbar-shadow);
    padding: 10px;
    background-color: white;
    z-index: 10;
}
.Navbar_childContainer__14V5h{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Navbar_logoContainer__23TLf{
    height: 50px;
    width: auto;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Navbar_abLogoImageContainer__3tBNu{
    height: 50px;
    width: 45px;
}
.Navbar_logoName__2utc4{
  padding-left:13px;
  font-weight: 900;
  font-size: var(--font-sm);
  cursor: default;
  margin: 0px;
}
.Navbar_logoContainer__23TLf img {
  width: 100%;
  height: 100%;
}
.Navbar_mobileNavContainer__1j10m {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navbar_mobileNavContainer__1j10m button {
  display: flex;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0, 0.2);
}
.Navbar_mobileLinkContainer__-C5Zg{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    left: 0;
    right: 0;
    top:98%;
    padding: 3px;
    background-color: white;
}
.Navbar_mobileLinkContainer__-C5Zg a {
  text-decoration: none;
  padding: 7px;
}
.Navbar_mobileNavContainer__1j10m button img {
  width: 100%;
}
.Navbar_showNavbarMenu__3aTz7 {
  display: flex;
}
.Navbar_collapseNavbarMenu__2ccTH {
  display: none;
}
.Navbar_inactiveText__3Ra4U {
  color: black;
}
.Navbar_activeText__QiUOF {
  color: var(--primary-color);
  font-weight: 600;
}
.Navbar_desktopLinkContainer__2DnJi{
    display: none;
    flex-basis: 55%;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (min-width:768px){
  .Navbar_childContainer__14V5h{
      width: 80%;
  }
  .Navbar_desktopLinkContainer__2DnJi{
      display: flex;
  }
  .Navbar_desktopLinkContainer__2DnJi a{
      text-decoration: none;
  }
  .Navbar_mobileNavContainer__1j10m{
      display: none;
  }
  .Navbar_mobileLinkContainer__-C5Zg{
      display: none;
  }
}
@media screen and (min-width:1024px){
  .Navbar_desktopLinkContainer__2DnJi{
      flex-basis: 40%;
  }
  .Navbar_abLogoImageContainer__3tBNu{
    height: 60px;
    width: 55px;
  }
}

.Fallback_fallbackContainer__2aknT {
  -webkit-animation: Fallback_fadeout__1IIqO 3s infinite;
          animation: Fallback_fadeout__1IIqO 3s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.Fallback_fallbackContainerImage__2wHql {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Fallback_fallbackContainerImage__2wHql img {
  max-width: 100%;
  max-height: 100%;
}

@-webkit-keyframes Fallback_fadeout__1IIqO {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Fallback_fadeout__1IIqO {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

